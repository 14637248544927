<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { getId } from "../utils/utils";

const RoomsRepository = RepositoryFactory.get("rooms");

let urlCode = "";
const router = useRouter();
const isCorrectCode = ref(true);

async function joinStream() {
    try {
        const response = await RoomsRepository.getRoom(urlCode);
        const room = response.data;
        isCorrectCode.value = true;

        if (isCorrectCode.value) {
            router.push({ path: `/stream/${getId(room.self)}`, query: { roomName: room.name } });
        }
    } catch (error) {
        console.log(error);
        isCorrectCode.value = false;
    }
}

onMounted(() => {
    // Hide sidebar and hamburguer
    if (document.getElementById("app")) {
        document.getElementById("app").firstElementChild.classList.add("layout-static-sidebar-inactive");
    }

    document.getElementById("hamburguer").style.display = "none";
});
</script>

<template>
    <main class="md:mt-8">
        <div class="flex flex-column justify-content-center align-items-center">
            <img src="../../public/images/undraw_conversation_re_c26v.svg" alt="Bemyvega Logo" class="coffee-image" />
            <h1 class="text-center">{{ $t("endpoints.code-access.header-1") }}</h1>
            <h5 class="text-center">{{ $t("endpoints.code-access.subheader") }}</h5>
            <div class="flex inline">
                <span class="p-input-icon-left">
                    <i class="pi pi-key" />
                    <InputText v-model="urlCode" type="text" :class="{ 'p-invalid': !isCorrectCode, 'p-inputtext-lg': true, 'input-access-code': true, 'w-20rem': true }" placeholder="ABCdEfG1hijkLm2nOpQrst" @keydown.enter="joinStream" />
                    <!-- <InputText id="access-code" v-model="urlCode" type="text" placeholder="" class="w-2rem" @keydown.enter="joinStream" /> -->
                </span>
                <Button icon="pi pi-arrow-right" class="p-button-primary arrow-button" @click="joinStream" />
            </div>
            <small v-if="!isCorrectCode" class="p-error mt-2">{{ $t("endpoints.code-access.error-msg") }}</small>
        </div>
    </main>
</template>

<style scoped>
.input-access-code {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.arrow-button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.coffee-image {
    max-height: 400px;
    width: 80%;
}
</style>
